<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" width="1200px" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose">
            <div v-if="showServe == true">
                <div style="cursor: pointer; font-size: 18px; padding-bottom: 15px; color: #2ea7e0" @click="showServe = false">
                    返回
                </div>
                <div class="now">企胖胖平台服务协议、隐私权政策、法律说明如下</div>
                <div v-html="textContent"></div>
            </div>
            <div class="byGoodBox" v-if="showServe == false">
                <div class="now">你当前正在</div>
                <el-steps :active="active" align-center>
                    <el-step title="提交需求"></el-step>
                    <el-step title="接单"></el-step>
                    <el-step title="签署合同"></el-step>
                    <el-step title="付款"></el-step>
                    <el-step title="服务方工作"></el-step>
                    <el-step title="验收成果"></el-step>
                    <el-step title="交易完成"></el-step>
                </el-steps>
                <div class="state" v-if="active==0">
                    <img src="../../assets/images/serve/gth.png" alt="" />
                    正在购买{{ goodInfo.shopName }}的服务 <span>{{ goodInfo.name }}</span>
                </div>
                <div class="state" v-if="active==1">
                    <img src="../../assets/images/serve/gth.png" alt="" />
                    正在购买{{ goodInfo.shopName }}的服务 <span>等待商家处理</span>
                </div>
                <div class="state" v-if="active==2">
                    <img src="../../assets/images/serve/gth.png" alt="" />
                    正在购买{{ goodInfo.shopName }}的服务 <span>等待商家上传合同</span>
                </div>
                <div class="state" v-if="active==3">
                    <img src="../../assets/images/serve/gth.png" alt="" />
                    正在购买{{ goodInfo.shopName }}的服务 <span>正在付款</span>
                </div>
                <div class="state" v-if="active==4">
                    <img src="../../assets/images/serve/gth.png" alt="" />
                    正在购买{{ goodInfo.shopName }}的服务 <span>等待服务方工作</span>
                </div>
                <div class="state" v-if="active==5">
                    <img src="../../assets/images/serve/gth.png" alt="" />
                    正在购买{{ goodInfo.shopName }}的服务 <span>请验收成果</span>
                </div>
                <div class="state" v-if="active==6">
                    <img src="../../assets/images/serve/gth.png" alt="" />
                    <span>交易完成</span>
                </div>
                <div class="state" v-if="active==7">
                    <img src="../../assets/images/serve/gth.png" alt="" />
                    <span>交易完成</span>
                </div>
                <div class="state" v-if="active==8">
                    <img src="../../assets/images/serve/gth.png" alt="" />
                    <span>交易完成</span>
                </div>
                <!-- 提交需求 -->
                <div v-show="active == 0 && !represent">
                    <!-- 提交需求 -->
                    <div class="look">
                        <div style="width: 80px; color: #666">*我需要</div>
                        <el-input style="padding: 10px 10px" :autosize="{ minRows: 4, maxRows: 10 }" type="textarea" placeholder="120字以内" v-model="form1.needMsg"></el-input>
                    </div>
                    <el-form style="margin-top: 40px" label-position="left" label-width="100px" :model="form1" @submit.native.prevent>
                        <el-form-item label="*服务地区">
                            <div class="tags">
                                <div v-for="(item, index) in goodInfo.address" :class="index == areaIndex ? 'active' : ''" @click="areaIndex = index" :key="index">
                                    {{ item }}
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="*服务规格(用企业人数)" v-if="goodInfo.norms != ''">
                            <div class="tags">
                                <div v-for="(item, index) in goodInfo.norms" :class="index == peopleIndex ? 'active' : ''" @click="peopleIndex = index" :key="index">
                                    {{ item }}
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="*有效期" v-if="goodInfo.term != ''">
                            <div class="tags">
                                <div v-for="(item, index) in goodInfo.term" :class="index == yearsIndex ? 'active' : ''" @click="yearsIndex = index" :key="index">
                                    {{ item }}
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="*购买预算">
                            <div class="code">
                                <input style="width: 160px" placeholder="请输入验证码" readonly v-model="allMoney" type="number" />
                                <span style="margin: 0 30px 0 10px">元</span>
                                <div v-if="goodInfo.num == -1">
                                    <el-input-number style="margin: 0 10px" v-model="num" @change="handleChange" :min="1" label="描述文字"></el-input-number>
                                </div>
                                <div v-else>
                                    <el-input-number style="margin: 0 10px" v-model="num" @change="handleChange" :min="1" :max="goodInfo.num" label="描述文字"></el-input-number>
                                    (库存{{ goodInfo.num }}件)
                                </div>
                            </div>
                        </el-form-item>
                        <div class="blue">
                            下单后将商家的联系方式发送至该账户，平台将监督与保障双方的权利和利益
                        </div>
                        <el-form-item label="*联系电话">
                            <div class="code">
                                <input style="width: 232px" readonly v-model="info.accountPhone" type="number" />
                            </div>
                        </el-form-item>
                        <el-form-item label="图形验证码:">
                            <div class="code">
                                <input style="width: 364px" v-model="smaCode" type="text" />
                            </div>
                            <!-- <el-input style="width: 364px;height:50px" v-model="form.smaCode"></el-input> -->
                            <div class="verification">
                                <Code ref="ref_validateCode" @change="changeCode" />
                                <div class="text" @click="changeImg">看不清？<span>换张图</span></div>
                            </div>
                        </el-form-item>
                        <el-form-item label="手机验证码">
                            <div class="code">
                                <input style="width: 360px" v-model="form1.smsCode" type="text" />
                                <button @click="getCode" v-show="!countDownShow">发送验证码</button>
                                <button @click="settime" :disabled="isdisabledFn" v-show="countDownShow">
                                    {{ countDown }}
                                </button>
                            </div>
                        </el-form-item>

                        <div class="blue">为保护你的隐私权益，已对商家隐藏你的真实手机号</div>
                        <div class="checked">
                            <el-checkbox v-model="checked"></el-checkbox>
                            <div style="margin-left: 10px">
                                已阅读并同意<span @click="showServe = true" style="cursor: pointer">企胖胖平台服务协议、隐私权政策、法律说明</span>
                            </div>
                        </div>
                        <div class="foot-btn">
                            <el-button class="orders" @click="compare">立即下单</el-button>
                        </div>
                    </el-form>
                </div>
                <!-- 接单 等待商家处理-->
                <div v-show="active == 1 && !represent">
                    <div class="orderInformation">
                        <div class="cell">
                            <div>订单号</div>
                            <span>{{ goodInfo.id }}</span>
                        </div>
                        <div class="cell">
                            <div>订单名称</div>
                            <span class="red">{{ goodInfo.goodsName }}</span>
                        </div>
                        <div class="cell">
                            <div>下单预算</div>
                            <span>￥{{ goodInfo.buyMoneyBudget }}</span>
                        </div>
                        <div class="cell">
                            <div>服务地区</div>
                            <span>{{ goodInfo.buyNorms }} </span>
                        </div>
                        <div class="cell">
                            <div>需求方</div>
                            <span>{{ info.accountKey }}</span>
                        </div>
                        <div class="cell">
                            <div>需求描述</div>
                            <span>{{ goodInfo.buyDemand }}</span>
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span>{{ goodInfo.buyPhone }}（号码保护中）</span>
                        </div>
                    </div>
                    <div class="accepting">
                        <div class="cell">
                            <div>服务店铺</div>
                            {{ goodInfo.shopName }}
                        </div>
                        <div class="cell">
                            <div>服务金额</div>
                            待填写
                        </div>
                        <div class="cell">
                            <div>我接受</div>
                            {{ className }} | {{ goodInfo.buyDemand }}
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span style="color: #2ea7e0" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ goodInfo.shopPhone }}</span>
                        </div>
                        <div class="cell">
                            <div>合同附件</div>
                            <span class="red">待上传</span>
                        </div>
                    </div>

                    <!-- 底部按钮 -->
                    <div v-if="obtain == 0">
                        <div>
                            <div class="foot-btn">
                                <el-button class="orders wait-btn">等待商家操作</el-button>
                            </div>
                        </div>
                    </div>
                    <!-- 手动确认拒绝 -->
                    <div v-else>
                        <div class="backJd">
                            <i style="font-size: 20px; padding-top: 2px" class="el-icon-arrow-left"></i>
                            返回接单页面
                        </div>
                        <el-form style="margin-top: 40px" label-position="left" label-width="150px" :model="form" @submit.native.prevent>
                            <el-form-item label="拒绝接单原因：">
                                <div class="code">
                                    <el-input type="textarea" placeholder="请输入问题描述，40个字符" v-model="form.money"></el-input>
                                </div>
                            </el-form-item>
                            <el-form-item label="相关图片(支持jpg、png格式图片)：">
                                <div>
                                    <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card" :auto-upload="false">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <div class="blue" style="margin-top: 10px">
                                        单张图片不超过20M，最多4张
                                    </div>
                                </div>
                            </el-form-item>
                        </el-form>
                        <!-- 底部按钮 -->
                        <div>
                            <div class="foot-btn">
                                <el-button class="orders" @click="pay">确认拒绝</el-button>
                            </div>
                        </div>
                    </div>
                    <!-- 只读拒绝 -->
                    <div style="display: none">
                        <!-- <div class="no-win">
                        <img style="width:65px;height:57px" src="../../assets/images/serve/mj.png" alt="">
                        已拒绝你的接单请求请通知买家
                    </div> -->
                        <div class="wait">
                            商家已拒绝接单!可以去看看<span class="red">服务中心</span> 其他合适的店铺
                        </div>
                        <br /><br />
                        <div class="reason"><span>拒绝接单原因:</span> xxxx</div>
                        <div class="reason"><span>相关图片:</span> xxxx</div>
                    </div>
                </div>
                <!-- 签署合同 等待商家上传合同-->
                <div v-show="active == 2 && !represent">
                    <div class="orderInformation">
                        <div class="cell">
                            <div>订单号</div>
                            <span>{{ goodInfo.id }}</span>
                        </div>
                        <div class="cell">
                            <div>订单名称</div>
                            <span class="red">{{ goodInfo.goodsName }}</span>
                        </div>
                        <div class="cell">
                            <div>下单预算</div>
                            <span>￥{{ goodInfo.buyMoneyBudget }}</span>
                        </div>
                        <div class="cell">
                            <div>服务地区</div>
                            <span>{{ goodInfo.buyNorms }}</span>
                        </div>
                        <div class="cell">
                            <div>需求方</div>
                            <span>{{ info.accountKey }}</span>
                        </div>
                        <div class="cell">
                            <div>需求描述</div>
                            <span>{{ goodInfo.buyDemand }}</span>
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span>{{ goodInfo.buyPhone }}（号码保护中）</span>
                        </div>
                    </div>
                    <!-- 等待中 -->
                    <div class="accepting">
                        <div class="cell">
                            <div>服务店铺</div>
                            {{ goodInfo.shopName }}
                        </div>
                        <div class="cell">
                            <div>服务金额</div>
                            ￥{{ goodInfo.money }}
                        </div>
                        <div class="cell">
                            <div>我接受</div>
                            {{ goodInfo.calssText }} | {{ goodInfo.buyDemand }}
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span style="color: #2ea7e0" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ goodInfo.shopPhone }}</span>
                        </div>
                        <div class="cell">
                            <div>合同附件</div>
                            <span class="red">待上传</span>
                        </div>
                    </div>

                    <!-- 底部按钮 -->
                    <div>
                        <div class="foot-btn">
                            <el-button class="orders wait-btn">等待商家上传合同</el-button>
                        </div>
                    </div>
                </div>
                <!-- 付款  正在付款-->
                <div v-show="active == 3 && !represent">
                    <div class="orderInformation">
                        <div class="cell">
                            <div>订单号</div>
                            <span>{{ goodInfo.id }}</span>
                        </div>
                        <div class="cell">
                            <div>订单名称</div>
                            <span class="red">{{ goodInfo.goodsName }}</span>
                        </div>
                        <div class="cell">
                            <div>下单预算</div>
                            <span>￥{{ goodInfo.buyMoneyBudget }}</span>
                        </div>
                        <div class="cell">
                            <div>服务地区</div>
                            <span>{{ goodInfo.buyNorms }}</span>
                        </div>
                        <div class="cell">
                            <div>需求方</div>
                            <span>{{ info.accountKey }}</span>
                        </div>
                        <div class="cell">
                            <div>需求描述</div>
                            <span>{{ goodInfo.buyDemand }}</span>
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span>{{ goodInfo.buyPhone }}（号码保护中）</span>
                        </div>
                    </div>
                    <!-- 等待中 -->
                    <div class="accepting">
                        <div class="cell">
                            <div>服务店铺</div>
                            {{ goodInfo.shopName }}
                        </div>
                        <div class="cell">
                            <div>服务金额</div>
                            ￥{{ goodInfo.money }}
                        </div>
                        <div class="cell">
                            <div>我接受</div>
                            {{ goodInfo.calssText }} | {{ goodInfo.buyDemand }}
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span style="color: #2ea7e0" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ goodInfo.shopPhone }}</span>
                        </div>
                        <div class="cell">
                            <div>合同附件</div>
                            <a :href="`${$util.host}/file/download?fileUrl=${goodInfo.contractUrl}&token=${token}`" target="_blank">
                                <i style="font-size: 50px; margin-left: 10px" class="el-icon-document"></i>
                                <span>下载</span>
                            </a>
                        </div>
                    </div>

                    <div class="hint">*支付方式</div>
                    <div class="payment">
                        <el-radio v-model="form2.payType" :label="0" class="raido">
                            <div class="raido-info">
                                <div>
                                    <img style="width: 20px; height: 19px" src="../../assets/images/serve/wdqb.png" alt="" />
                                    我的钱包
                                    <span>余额：{{ info.balance }}元</span>
                                </div>
                            </div>
                        </el-radio>
                        <div>平台</div>
                    </div>
                    <div class="payment">
                        <el-radio v-model="form2.payType" :label="1" class="raido">
                            <div class="raido-info">
                                <div>
                                    <img style="width: 22px; height: 22px" src="../../assets/images/serve/zfb.png" alt="" />
                                    支付宝
                                </div>
                            </div>
                        </el-radio>
                    </div>
                    <div class="payment">
                        <el-radio v-model="form2.payType" :label="2" class="raido">
                            <div class="raido-info">
                                <div>
                                    <img style="width: 21px; height: 18px" src="../../assets/images/serve/wx.png" alt="" />
                                    微信
                                </div>
                            </div>
                        </el-radio>
                    </div>
                    <div class="hint">*预付金额</div>
                    <div class="backJd">
                        <div class="ysmoney">{{ goodInfo.money }}</div>
                        <div style="margin-left: 20px">开具发票请自行与服务商协定</div>
                    </div>
                    <div v-show="form2.payType == 0">
                        <div class="hint">*支付密码</div>
                        <div class="paypass">
                            <input v-for="(item, index) in captchas" type="password" :key="index" v-model="item.num" :id="'captcha' + index" @input="inputFinash(index)" @focus="adjust(index)" @keydown="inputDirection(index)" class="captcha_input_box row-center" maxlength="1" />
                        </div>
                    </div>
                    <!-- 二维码显示 -->
                    <div class="qr-code" v-show="qrCodeShow" ref="qrCodeUrl"></div>
                    <div class="privacy">你的隐私正在保护中...</div>
                    <!-- 底部按钮 -->
                    <div class="foot-btn">
                        <el-button class="orders" @click="payOrder" v-show="!qrCodeShow">付款</el-button>
                        <div class="give-up" @click="appealOpen">申诉</div>
                    </div>

                </div>
                <!-- 服务方工作 等待服务方工作-->
                <div v-show="active == 4 && !represent">
                    <div class="orderInformation">
                        <div class="cell">
                            <div>订单号</div>
                            <span>{{ goodInfo.id }}</span>
                        </div>
                        <div class="cell">
                            <div>订单名称</div>
                            <span class="red">{{ goodInfo.goodsName }}</span>
                        </div>
                        <div class="cell">
                            <div>下单预算</div>
                            <span>￥{{ goodInfo.buyMoneyBudget }}</span>
                        </div>
                        <div class="cell">
                            <div>服务地区</div>
                            <span>{{ goodInfo.buyNorms }}</span>
                        </div>
                        <div class="cell">
                            <div>需求方</div>
                            <span>{{ info.accountKey }}</span>
                        </div>
                        <div class="cell">
                            <div>需求描述</div>
                            <span>{{ goodInfo.buyDemand }}</span>
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span>{{ goodInfo.buyPhone }}（号码保护中）</span>
                        </div>
                    </div>
                    <!-- 等待中 -->
                    <div class="accepting">
                        <div class="cell">
                            <div>服务店铺</div>
                            {{ goodInfo.shopName }}
                        </div>
                        <div class="cell">
                            <div>服务金额</div>
                            ￥{{ goodInfo.money }}
                        </div>
                        <div class="cell">
                            <div>我接受</div>
                            {{ goodInfo.calssText }} | {{ goodInfo.buyDemand }}
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span style="color: #2ea7e0" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ goodInfo.shopPhone }}</span>
                        </div>
                        <div class="cell">
                            <div>合同附件</div>
                            <a :href="`${$util.host}/file/download?fileUrl=${goodInfo.contractUrl}&token=${token}`" target="_blank">
                                <i style="font-size: 50px; margin-left: 10px" class="el-icon-document"></i>
                                <span>下载</span>
                            </a>
                        </div>
                    </div>
                    <div class="no-win">
                        <img src="../../assets/images/serve/gzz.png" alt="" />
                        服务方工作中......
                    </div>
                    <!-- 底部按钮 -->
                    <div>
                        <!-- <div class="foot-btn">
                        <el-button class="orders" @click="pay">确认</el-button>
                    </div> -->
                        <div class="foot-btn">
                            <el-button class="orders wait-btn">等待服务方上传验收文件</el-button>
                            <div class="give-up" @click="appealOpen">申诉</div>
                        </div>
                        <!-- <div class="hui">此流程7个工作日后启动跳转至下个流程</div> -->
                    </div>
                </div>
                <!-- 验收成果  请验收成果-->
                <div v-show="active == 5 && !represent">
                    <div class="orderInformation">
                        <div class="cell">
                            <div>订单号</div>
                            <span>{{ goodInfo.id }}</span>
                        </div>
                        <div class="cell">
                            <div>订单名称</div>
                            <span class="red">{{ goodInfo.goodsName }}</span>
                        </div>
                        <div class="cell">
                            <div>下单预算</div>
                            <span>￥{{ goodInfo.buyMoneyBudget }}</span>
                        </div>
                        <div class="cell">
                            <div>服务地区</div>
                            <span>{{ goodInfo.buyNorms }}</span>
                        </div>
                        <div class="cell">
                            <div>需求方</div>
                            <span>{{ info.accountKey }}</span>
                        </div>
                        <div class="cell">
                            <div>需求描述</div>
                            <span>{{ goodInfo.buyDemand }}</span>
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span>{{ goodInfo.buyPhone }}（号码保护中）</span>
                        </div>
                    </div>
                    <!-- 等待中 -->
                    <div class="accepting">
                        <div class="cell">
                            <div>服务店铺</div>
                            {{ goodInfo.shopName }}
                        </div>
                        <div class="cell">
                            <div>服务金额</div>
                            ￥{{ goodInfo.money }}
                        </div>
                        <div class="cell">
                            <div>我接受</div>
                            {{ goodInfo.calssText }} | {{ goodInfo.buyDemand }}
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span style="color: #2ea7e0" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ goodInfo.shopPhone }}</span>
                        </div>
                        <div class="cell">
                            <div>合同附件</div>
                            <a :href="`${$util.host}/file/download?fileUrl=${goodInfo.contractUrl}&token=${token}`" target="_blank">
                                <i style="font-size: 30px" class="el-icon-document"></i>
                                <span>下载</span></a>
                        </div>
                    </div>
                    <div class="upload">
                        <div class="lable">验收文件</div>
                        <a :href="`${$util.host}/file/download?fileUrl=${goodInfo.fileUrl}&token=${token}`" target="_blank">
                            <i style="font-size: 30px; color: #2ea7e0" class="el-icon-document"></i>
                            <span>下载</span>
                        </a>
                    </div>
                    <!-- 底部按钮 -->
                    <div>
                        <!-- <div class="wait"> 请在此流程的三天内进行确认，以方便进行后续工作安排，若三天内未确认次流程，则视为服务方放弃拍价</div> -->
                        <div class="foot-btn">
                            <el-button class="orders" @click="acceptance">确认验收</el-button>
                            <div class="give-up" @click="appealOpen">申诉</div>
                        </div>
                        <!-- <div class="foot-btn">
                        <el-button class="orders wait-btn" @click="pay">等待服务方验收确认与支付尾款</el-button>
                        <div class="give-up">申诉</div>
                    </div>
                    <div class="foot-btn">
                        <div class="hui">需求方正在付款的路上...</div>
                    </div> -->
                    </div>
                </div>
                <!-- 交易中  交易完成-->
                <div v-show="active == 6 && !represent">
                    <div class="orderInformation">
                        <div class="cell">
                            <div>订单号</div>
                            <span>{{ goodInfo.id }}</span>
                        </div>
                        <div class="cell">
                            <div>订单名称</div>
                            <span class="red">{{ goodInfo.goodsName }}</span>
                        </div>
                        <div class="cell">
                            <div>下单预算</div>
                            <span>￥{{ goodInfo.buyMoneyBudget }}</span>
                        </div>
                        <div class="cell">
                            <div>服务地区</div>
                            <span>{{ goodInfo.buyNorms }}</span>
                        </div>
                        <div class="cell">
                            <div>需求方</div>
                            <span>{{ info.accountKey }}</span>
                        </div>
                        <div class="cell">
                            <div>需求描述</div>
                            <span>{{ goodInfo.buyDemand }}</span>
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span>{{ goodInfo.buyPhone }}（号码保护中）</span>
                        </div>
                    </div>
                    <!-- 等待中 -->
                    <div class="accepting">
                        <div class="cell">
                            <div>服务店铺</div>
                            {{ goodInfo.shopName }}
                        </div>
                        <div class="cell">
                            <div>服务金额</div>
                            ￥{{ goodInfo.money }}
                        </div>
                        <div class="cell">
                            <div>我接受</div>
                            {{ goodInfo.calssText }} | {{ goodInfo.buyDemand }}
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span style="color: #2ea7e0" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ goodInfo.shopPhone }}</span>
                        </div>
                        <div class="cell">
                            <div>合同附件</div>
                            <a :href="`${$util.host}/file/download?fileUrl=${goodInfo.contractUrl}&token=${token}`" target="_blank">
                                <i style="font-size: 30px" class="el-icon-document"></i>
                                <span>下载</span></a>
                        </div>
                    </div>
                    <div class="upload">
                        <div class="lable">验收文件</div>
                        <a :href="`${$util.host}/file/download?fileUrl=${goodInfo.fileUrl}&token=${token}`" target="_blank">
                            <i style="font-size: 30px; color: #2ea7e0" class="el-icon-document"></i>
                            <span>下载</span>
                        </a>
                    </div>
                    <div class="no-win">
                        <img src="../../assets/images/serve/qr.png" alt="" />
                        交易已完成！请对本次服务进行评价
                    </div>
                    <el-form style="margin-top: 40px" label-position="left" label-width="100px" :model="form3" @submit.native.prevent>
                        <el-form-item label="综合评分">
                            <el-rate v-model="form3.level" style="margin-bottom: 20px" @change="changeStar" show-text :colors="colors" :texts="['极差', '失望', '一般', '满意', '好评']" text-color="#666666"></el-rate>
                        </el-form-item>
                        <el-form-item label="评价描述">
                            <div class="code">
                                <el-input style="padding: 10px" :autosize="{ minRows: 4, maxRows: 10 }" type="textarea" placeholder="描述服务的质量与响应速度，你们的评价能让我们的服务更上一层楼！" v-model="form3.txt"></el-input>
                            </div>
                        </el-form-item>
                    </el-form>
                    <!-- 底部按钮 -->
                    <div>
                        <div class="foot-btn">
                            <el-button class="orders" @click="comment">提交评价</el-button>
                            <div class="give-up" style="color: #999" @click="after">以后再说</div>
                        </div>
                    </div>
                </div>
                <!-- 交易中 交易完成-->
                <div v-show="active == 7 && !represent">
                    <div class="orderInformation">
                        <div class="cell">
                            <div>订单号</div>
                            <span>{{ goodInfo.id }}</span>
                        </div>
                        <div class="cell">
                            <div>订单名称</div>
                            <span class="red">{{ goodInfo.goodsName }}</span>
                        </div>
                        <div class="cell">
                            <div>下单预算</div>
                            <span>￥{{ goodInfo.buyMoneyBudget }}</span>
                        </div>
                        <div class="cell">
                            <div>服务地区</div>
                            <span>{{ goodInfo.buyNorms }}</span>
                        </div>
                        <div class="cell">
                            <div>需求方</div>
                            <span>{{ info.accountKey }}</span>
                        </div>
                        <div class="cell">
                            <div>需求描述</div>
                            <span>{{ goodInfo.buyDemand }}</span>
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span>{{ goodInfo.buyPhone }}（号码保护中）</span>
                        </div>
                    </div>
                    <!-- 等待中 -->
                    <div class="accepting">
                        <div class="cell">
                            <div>服务店铺</div>
                            {{ goodInfo.shopName }}
                        </div>
                        <div class="cell">
                            <div>服务金额</div>
                            ￥{{ goodInfo.money }}
                        </div>
                        <div class="cell">
                            <div>我接受</div>
                            {{ goodInfo.calssText }} | {{ goodInfo.buyDemand }}
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span style="color: #2ea7e0" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ goodInfo.shopPhone }}</span>
                        </div>
                        <div class="cell">
                            <div>合同附件</div>
                            <a :href="`${$util.host}/file/download?fileUrl=${goodInfo.contractUrl}&token=${token}`" target="_blank">
                                <i style="font-size: 30px" class="el-icon-document"></i>
                                <span>下载</span></a>
                        </div>
                    </div>
                    <div class="upload">
                        <div class="lable">验收文件</div>
                        <a :href="`${$util.host}/file/download?fileUrl=${goodInfo.fileUrl}&token=${token}`" target="_blank">
                            <i style="font-size: 30px; color: #2ea7e0" class="el-icon-document"></i>
                            <span>下载</span>
                        </a>
                    </div>
                    <div class="no-win">
                        <img src="../../assets/images/serve/qr.png" alt="" />
                        交易已完成！
                    </div>
                    <!-- <dir class="evaluation">评价</dir>
                <div class="evaluation-info">
                    <div class="cell">
                        <div class="title">订单名称</div>知识产权|xxx公司计算机软件著作申请
                    </div>
                    <div class="cell">
                        <div class="title">综合评分</div>
                        <el-rate :value="1" disabled show-text :colors="colors" :texts="['极差', '失望', '一般', '满意', '好评']" text-color="#666666">
                        </el-rate>
                    </div>
                    <div class="cell">
                        <div class="title">评用户评价</div>xxxxxxxxxxxx
                    </div>
                </div> -->
                    <!-- <div class="hui">平台会在3个工作日内将款项汇至指定账户，请耐心等待</div> -->
                </div>
                <!-- 交易完成 交易完成-->
                <div v-show="active == 8 && !represent">
                    <div class="orderInformation">
                        <div class="cell">
                            <div>订单号</div>
                            <span>{{ goodInfo.id }}</span>
                        </div>
                        <div class="cell">
                            <div>订单名称</div>
                            <span class="red">{{ goodInfo.goodsName }}</span>
                        </div>
                        <div class="cell">
                            <div>下单预算</div>
                            <span>￥{{ goodInfo.buyMoneyBudget }}</span>
                        </div>
                        <div class="cell">
                            <div>服务地区</div>
                            <span>{{ goodInfo.buyNorms }}</span>
                        </div>
                        <div class="cell">
                            <div>需求方</div>
                            <span>{{ info.accountKey }}</span>
                        </div>
                        <div class="cell">
                            <div>需求描述</div>
                            <span>{{ goodInfo.buyDemand }}</span>
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span>{{ goodInfo.buyPhone }}（号码保护中）</span>
                        </div>
                    </div>
                    <!-- 等待中 -->
                    <div class="accepting">
                        <div class="cell">
                            <div>服务店铺</div>
                            {{ goodInfo.shopName }}
                        </div>
                        <div class="cell">
                            <div>服务金额</div>
                            ￥{{ goodInfo.money }}
                        </div>
                        <div class="cell">
                            <div>我接受</div>
                            {{ goodInfo.calssText }} | {{ goodInfo.buyDemand }}
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span style="color: #2ea7e0" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ goodInfo.shopPhone }}</span>
                        </div>
                        <div class="cell">
                            <div>合同附件</div>
                            <a :href="`${$util.host}/file/download?fileUrl=${goodInfo.contractUrl}&token=${token}`" target="_blank">
                                <i style="font-size: 30px" class="el-icon-document"></i>
                                <span>下载</span></a>
                        </div>
                    </div>
                    <div class="upload">
                        <div class="lable">验收文件</div>
                        <a :href="`${$util.host}/file/download?fileUrl=${goodInfo.fileUrl}&token=${token}`" target="_blank">
                            <i style="font-size: 30px; color: #2ea7e0" class="el-icon-document"></i>
                            <span>下载</span>
                        </a>
                    </div>
                    <div class="no-win">
                        <img src="../../assets/images/serve/good.png" style="width: 103px; height: 108px" alt="" />
                        感谢你的评价！可前往服务中心逛逛其他需求
                    </div>
                </div>
                <!-- 申述 -->
                <div v-if="represent">
                    <div class="orderInformation">
                        <div class="cell">
                            <div>订单号</div>
                            <span>{{ goodInfo.id }}</span>
                        </div>
                        <div class="cell">
                            <div>订单名称</div>
                            <span class="red">{{ goodInfo.goodsName }}</span>
                        </div>
                        <div class="cell">
                            <div>下单预算</div>
                            <span>￥{{ goodInfo.buyMoneyBudget }}</span>
                        </div>
                        <div class="cell">
                            <div>服务地区</div>
                            <span>{{ goodInfo.buyNorms }}</span>
                        </div>
                        <div class="cell">
                            <div>需求方</div>
                            <span>{{ info.accountKey }}</span>
                        </div>
                        <div class="cell">
                            <div>需求描述</div>
                            <span>{{ goodInfo.buyDemand }}</span>
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span>{{ goodInfo.buyPhone }}（号码保护中）</span>
                        </div>
                    </div>
                    <!-- 等待中 -->
                    <div class="accepting">
                        <div class="cell">
                            <div>服务店铺</div>
                            {{ goodInfo.shopName }}
                        </div>
                        <div class="cell">
                            <div>服务金额</div>
                            ￥{{ goodInfo.money }}
                        </div>
                        <div class="cell">
                            <div>我接受</div>
                            {{ goodInfo.calssText }} | {{ goodInfo.buyDemand }}
                        </div>
                        <div class="cell">
                            <div>联系方式</div>
                            <span style="color: #2ea7e0" v-if="!getPhone" @click="getPhone = true">点击获取</span><span v-else>{{ goodInfo.shopPhone }}</span>
                        </div>
                        <div class="cell">
                            <div>合同附件</div>
                            <a :href="`${$util.host}/file/download?fileUrl=${goodInfo.contractUrl}&token=${token}`" target="_blank">
                                <i style="font-size: 30px" class="el-icon-document"></i>
                                <span>下载</span></a>
                        </div>
                    </div>
                    <div v-if="repreCur == 0">
                        <el-form style="margin-top: 40px" label-position="left" label-width="80px" :model="form" @submit.native.prevent>
                            <el-form-item label="*申诉环节">
                                <div class="code">
                                    <el-select v-model="form.state" placeholder="服务环节">
                                        <el-option v-for="item in linkList" :key="item.id" :label="item.title" :value="item.id"></el-option>
                                    </el-select>
                                </div>
                            </el-form-item>
                            <el-form-item label="*申诉原因">
                                <!-- <div class="code"> -->
                                <el-input style="padding: 10px" :autosize="{ minRows: 3, maxRows: 10 }" type="textarea" placeholder="50字以内" v-model="form.msg"></el-input>
                                <!-- </div> -->
                            </el-form-item>
                        </el-form>
                        <!-- 底部按钮 -->
                        <div>
                            <div class="foot-btn">
                                <el-button class="orders" @click="appeal">确定转入申诉</el-button>
                            </div>
                            <div class="hui">
                                申诉方请知悉：转入申诉后此流程将暂停，待申诉方撤回申诉后才可继续进行
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="orderInformation" style="border: none">
                            <div class="cell">
                                <div>申诉人</div>
                                <span>{{ info.accountKey }}</span>
                            </div>
                            <div class="cell">
                                <div>申诉环节</div>
                                <span>{{ errorState[active] }}</span>
                            </div>
                            <div class="cell">
                                <div>申诉原因</div>
                                <span>{{
                  goodInfo.errorMsg == "" ? this.form.msg : goodInfo.errorMsg
                }}</span>
                            </div>
                        </div>
                        <!-- 底部按钮 -->
                        <div>
                            <div class="foot-btn">
                                <el-button class="orders" @click="withdraw" v-if="notShowWithdraw">撤回申诉</el-button>
                                <div class="give-up">平台审核员核实中......</div>
                            </div>
                            <div class="hui">
                                申诉方请知悉：转入申诉后此流程将暂停，待申诉方撤回申诉后才可继续进行
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import QRCode from "qrcodejs2";
import Code from "../../components/code.vue";
export default {
    components: { Code },
    props: {
        dialogVisible: Boolean,
        goodInfo: Object,
        shopNum: Number,
    },

    data() {
        return {
            textContent: "",
            getPhone: false,
            peopleIndex: 0, //人数选择下标
            yearsIndex: 0, //年数下标选择
            areaIndex: 0, //地址下标选择
            num: this.shopNum, //数量
            active: 0, //当前进度下标
            checked: false, //是够勾选隐私条款
            star: 5,
            colors: ["#E40012", "#E40012", "#E40012"],
            obtain: 0, //获取信息 0 为输入信息 1 支付
            form1: {
                // 购买商品
                needMsg: "",
                addr: "",
                norms: "",
                time: "",
                money: "",
                phone: "",
                smsCode: "",
                goodsId: this.goodInfo.id,
            },
            form2: {
                //付款
                orderId: this.goodInfo.id,
                payType: 0,
                payPsd: "",
            },
            form3: {
                //评论
                orderId: this.goodInfo.id,
                level: 1,
                txt: "",
            },
            smaCode: "", //验证码
            form: {
                //申诉
                state: "",
                msg: "",
                orderId: "",
            },
            represent: false, // 申述
            repreCur: 0, //0为申诉页面  1为已进行申诉
            errorState: {
                //错误转态码
                3: "付款",
                4: "服务方工作",
                5: "验收成果",
            },
            linkList: [], //申诉转态
            radio: 0, //zhifu
            activeInput: 0,
            captchas: [
                // 密码
                { num: "" },
                { num: "" },
                { num: "" },
                { num: "" },
                { num: "" },
                { num: "" },
            ],
            // 倒计时
            countDownShow: false,
            count: 60,
            countDown: "",
            isdisabledFn: false,
            checkCode: "", //图形码
            qrCodeShow: false,
            info: {},
            token: "",
            className: "",
            showServe: false,
            notShowWithdraw: false,
            code: "",
            payId: "",
            codePayState: 0,
        };
    },
    watch: {
        active: {
            handler(val) {
                if (val == 3) {
                    this.linkList = [{ id: 3, title: "付款" }];
                } else if (val == 4) {
                    this.linkList = [
                        { id: 3, title: "付款" },
                        { id: 4, title: "服务方工作" },
                    ];
                } else if (val == 5) {
                    this.linkList = [
                        { id: 3, title: "付款" },
                        { id: 4, title: "服务方工作" },
                        { id: 6, title: "验收成果" },
                    ];
                }
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        this.getText();
        this.getClass();

        this.token = sessionStorage.getItem("token");
        // 是否存在goodInfo 存在就根据state展示当前的进度
        //这边的state走不通就去发布方进去点击进行下一步
        this.getInfo();
        this.BooleanRed();
        this.form1.needMsg = "我需要" + this.goodInfo.name;
        console.log(this.goodInfo);
        if (this.goodInfo) {
            if (this.goodInfo.errorState) {
                if (this.goodInfo.errorState != 0) {
                    this.represent = true;
                    this.repreCur = 1;
                    if (this.goodInfo.buyUid != this.goodInfo.errorUid) {
                        this.notShowWithdraw = false;
                    } else {
                        this.notShowWithdraw = true;
                    }
                }
            }
            if (this.goodInfo.state == 1) {
                this.active = 1;
            } else if (this.goodInfo.state == 2) {
                this.active = 1;
            } else if (this.goodInfo.state == 3) {
                this.active = 2;
            } else if (this.goodInfo.state == 4) {
                this.active = 3;
            } else if (this.goodInfo.state == 5) {
                this.active = 4;
            } else if (this.goodInfo.state == 6) {
                this.active = 5;
            } else if (this.goodInfo.state == 7) {
                this.active = 6;
            } else if (this.goodInfo.state == 8 && this.goodInfo.evaluate) {
                this.active = 7;
            } else if (this.goodInfo.state == 8 && !this.goodInfo.evaluate) {
                this.active = 7;
            }
        }
    },

    methods: {
        // 获取支付状态
        payGetState() {
            this.$util
                .post("/center/myOrder/getRecordState", {
                    id: this.payId,
                })
                .then((res) => {
                    console.log(res);
                    this.codePayState = res.data.state;
                });
        },
        creatQrCode() {
            this.qrCodeShow = true;
            var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                text: this.createdCode,
                width: 257,
                height: 257,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
            });
        },
        //消除红点
        BooleanRed() {
            if (this.goodInfo) {
                if (
                    this.goodInfo.redUid != "" &&
                    this.goodInfo.redUid != null &&
                    this.goodInfo.redUid != undefined
                ) {
                    this.$util
                        .post("/demandPurchase/reade", {
                            orderId: this.goodInfo.id,
                        })
                        .then((res) => {
                            console.log(res);
                        });
                }
            }
        },
        getText() {
            this.$util
                .post("/sys-param/list", {
                    paramKey: "html_yszc",
                })
                .then((res) => {
                    this.textContent = res.data[0].paramValue.replace(
                        /<img [^>]*src=['"]([^'"]+)[^>]*>/g,
                        (match, capture) => {
                            return match.replace("../../", this.$util.host + "/");
                        }
                    );
                });
        },
        getClass() {
            this.$util.post("/shop/allClassify").then((res) => {
                if (this.goodInfo != undefined) {
                    for (var i in res.data) {
                        if (this.goodInfo.classifyId == res.data[i].id) {
                            this.className = res.data[i].name;
                        }
                    }
                }
            });
        },
        getInfo() {
            this.$util.post("/user/info").then((res) => {
                this.info = res.data;
                this.form1.phone = res.data.accountPhone;
            });
        },
        handleChange(value) {
            console.log(value);
        },

        handleClose(done) {
            this.$emit("close");
        },
        // 获取验证码
        getCode() {
            // const email = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
            if (this.form1.phone == "") {
                return this.$message.error("请输入手机号");
            }
            this.$util
                .post("sys-sms/sms-send", {
                    phone: this.form1.phone,
                    action: "Other",
                })
                .then((res) => {
                    if (res.code == 200) {
                        this.countDownShow = true;
                        this.settime();
                    }
                });
        },

        settime() {
            if (this.count == 0) {
                this.count = 60;
                this.countDownShow = false;
                return false;
            } else {
                this.countDownShow = true;
                this.isdisabledFn = true;
                this.countDown = "重新发送" + "(" + this.count + ")";
                this.count--;
            }
            setTimeout(() => {
                this.settime();
            }, 1000);
        },
        changeCode(value) {
            this.checkCode = value;
        },

        changeImg() {
            this.$refs["ref_validateCode"].draw();
        },
        // 输入密码
        adjust(index) {
            let dom = document.getElementById("captcha" + this.activeInput);
            if (index !== this.activeInput && dom) {
                dom.focus();
            }
        },
        // 控制前后方向
        inputDirection(index) {
            let val = this.captchas[index].num;
            // 回退键处理
            if (event.keyCode == 8 && val == "") {
                // 重新校准
                let dom = document.getElementById("captcha" + (index - 1));
                this.activeInput = index - 1;
                if (dom) dom.focus();
            }
            if (event.keyCode != 8 && val != "") {
                let dom = document.getElementById("captcha" + (index + 1));
                this.activeInput = index + 1;
                if (dom) dom.focus();
            }
        },
        // 输入框相互联动
        inputFinash(index) {
            let val = this.captchas[index].num;
            this.activeInput = val ? index + 1 : index - 1;
            let dom = document.getElementById("captcha" + this.activeInput);
            if (dom) dom.focus();
            if (index == this.captchas.length - 1) {
                let code = this.captchas.map((x) => x.num).join("");
                if (code.length == 6) {
                    this.form2.payPsd = code;
                }
            }
        },
        // 输入密码2
        adjusto(index) {
            let dom = document.getElementById("captch" + this.activeInput);
            if (index !== this.activeInput && dom) {
                dom.focus();
            }
        },
        // 控制前后方向
        inputDirectiono(index) {
            let val = this.captchas[index].num;
            // 回退键处理
            if (event.keyCode == 8 && val == "") {
                // 重新校准
                let dom = document.getElementById("captch" + (index - 1));
                this.activeInput = index - 1;
                if (dom) dom.focus();
            }
            if (event.keyCode != 8 && val != "") {
                let dom = document.getElementById("captch" + (index + 1));
                this.activeInput = index + 1;
                if (dom) dom.focus();
            }
        },
        // 输入框相互联动
        inputFinasho(index) {
            let val = this.captchas[index].num;
            this.activeInput = val ? index + 1 : index - 1;
            let dom = document.getElementById("captch" + this.activeInput);
            if (dom) dom.focus();
            if (index == this.captchas.length - 1) {
                let code = this.captchas.map((x) => x.num).join("");
                if (code.length == 6) {
                    this.$emit("finish", code);
                }
            }
        },
        // 下单
        compare() {
            this.form1.addr = this.goodInfo.address[this.areaIndex];
            this.form1.norms = this.goodInfo.norms[this.peopleIndex];
            this.form1.time = this.goodInfo.term[this.yearsIndex];
			if(!this.checked) return this.$message.error('请先勾选协议')
            if (this.smaCode.toUpperCase() === this.checkCode) {
				console.log(54454)
                // if (this.active++ > 2) this.active = 0;
                this.$util.post("/demandPurchase/buy", this.form1).then((res) => {
                    console.log(res);
                    if (res.code == 200) {
                        this.active++;
                        this.goodInfo = res.data;
                        this.getClass();
                    } else {
                        this.$message.error(res.message);
                    }
                });
            } else {
                alert("验证码不正确,请重新输入");
                this.smaCode = "";
                this.$refs["ref_validateCode"].draw();
            }
        },
        // 付款
        payOrder() {
            this.$util.post("/demandPurchase/pay", this.form2).then((res) => {
                if (res.code == 200) {
                    if (this.form2.payType == 1 || this.form2.payType == 2) {
                        this.createdCode = res.data.code;
                        this.creatQrCode();
                        this.payId = res.data.id;
                        let timmer = setInterval(() => {
                            if (this.codePayState == "0") {
                                this.payGetState();
                            } else {
                                clearInterval(timmer);
                                if (this.codePayState == 1) {
                                    this.$emit("close");
                                    return this.$message.error("支付超时");
                                } else if (this.codePayState == 2) {
                                    this.active++;
                                    return this.$message.success("支付成功");
                                }
                            }
                        }, 2000);
                    } else {
                        this.active++;
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 验收
        acceptance() {
            this.$util
                .post("/demandPurchase/check", {
                    orderId: this.goodInfo.id,
                })
                .then((res) => {
                    if (res.code == 200) {
                        this.active++;
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
        // 评论
        changeStar(num) {
            this.form3.level = num;
        },
        comment() {
            this.$util.post("/demandPurchase/evaluateOrder", this.form3).then((res) => {
                if (res.code == 200) {
                    this.active = 8;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        //  下次再说
        after() {
            this.active = 7;
        },
        // 付款
        pay() {
            if (this.active++ > 8) this.active = 0;
        },
        // 申诉打开
        appealOpen() {
            this.represent = true;
        },
        // 申诉
        appeal() {
            this.form.orderId = this.goodInfo.id;
            console.log(this.form);

            this.$util.post("/demandPurchase/error/buy", this.form).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.repreCur = 1;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 撤回申诉
        withdraw() {
            this.$util
                .post("/demandPurchase/error/close", {
                    orderId: this.goodInfo.id,
                })
                .then((res) => {
                    console.log(res);
                    if (res.code == 200) {
                        this.represent = false;
                        this.repreCur = 0;
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
    },
    computed: {
        // 获取应交金额
        allMoney() {
            this.form1.money = this.num * this.goodInfo.budget;
            return this.num * this.goodInfo.budget;
        },
    },
};
</script>

<style lang="scss" scoped>
// 弹出框

.now {
    font-size: 18px;
    color: #999999;
    margin-bottom: 30px;
}
/deep/.el-step__title.is-wait {
    // background: #e40012;
    color: #666;
}
/deep/.el-step__title.is-process {
    color: #e40012;
}
/deep/.el-step__icon.is-text {
    color: #666666;
    width: 36px;
    height: 36px;
}
/deep/.el-step__head.is-process {
    border-color: #e40012;
}
/deep/.el-step__title.is-finish {
    color: #e40012;
}
/deep/.el-step__head.is-finish {
    border-color: #e40012;
}
.red {
    color: #e40012 !important;
}
.look {
    display: flex;
    align-items: center;
    color: #222;
    font-size: 16px;
    margin: 40px 0;
    div {
        color: #999999;
        margin-right: 20px;
    }
}
.blue {
    color: #2ea7e0;
    font-size: 12px;
    // margin-top: 10px ;
    margin-bottom: 30px;
}
.checked {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666666;
    span {
        color: #2ea7e0;
    }
}
.state {
    padding: 20px 0 10px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #333333;
    span {
        color: #e40012;
    }
    img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
}
// /deep/.el-input__inner {
//     width: 490px;
// }
.code {
    height: 48px;
    // border: 1px solid #dcdfe6;
    display: flex;
    align-items: center;
    // width: 500px;
    input {
        height: 100%;
        // border: none;
        outline: none;
        padding: 0 15px;
        border-radius: 4px;
        font-size: 16px;
        border: 1px solid #eeeeee;
    }
    button {
        background-color: transparent;
        border: none;
        color: #e40012;
        font-weight: bold;
        font-size: 14px;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
input::-webkit-input-placeholder {
    color: #c0c4cc;
}
input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #c0c4cc;
}
input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #c0c4cc;
}
input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c0c4cc;
}
.verification {
    display: flex;
    margin-top: 10px;
    align-items: center;
    .text {
        font-size: 14px;
        color: #666666;
        cursor: pointer;
        margin-left: 10px;
        span {
            color: #e40012;
        }
    }
}
.privacy {
    font-size: 16px;
    color: #00a71c;
    margin: 30px 0 40px;
}
.hint {
    font-size: 16px;
    color: #999999;
    margin: 30px 0;
}
.ysmoney {
    padding: 15px 60px;
    border: 1px solid #eeeeee;
    color: #e40012;
    font-size: 16px;
    // text-align: center;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
}
.payment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    padding: 15px 30px;
    border: 1px solid #eeeeee;
    margin-bottom: 20px;
    color: #999;
}
.raido {
    display: flex;
    align-items: center;
}
.raido-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-left: 10px;
    align-items: center;
    color: #222;
    div {
        display: flex;
        align-items: center;
    }
    img {
        margin-right: 10px;
        // margin-top: ;
    }
    span {
        color: #999999;
        font-size: 14px;
        margin-left: 10px;
    }
}
.deposit {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #222;
    span {
        color: #e40012;
    }
    .price {
        padding: 15px 48px;
        border: 1px solid #eeeeee;
        margin-right: 20px;
        text-align: center;
        div {
            color: #999999;
            font-size: 14px;
            margin-top: 10px;
        }
    }
}
.paypass {
    display: flex;
    align-items: center;
    input {
        width: 64px;
        height: 64px;
        border: 1px solid #d1d1d1;
        outline: none;
        text-align: center;
        line-height: 64px;
        font-size: 60px;
        margin-right: 20px;
    }
}
::-ms-clear {
    display: none;
}

::-ms-reveal {
    display: none;
}

.orderInformation {
    padding: 20px;
    border: 1px solid #eeeeee;
    margin: 30px 0 30px;
    .cell {
        display: flex;
        font-size: 16px;
        color: #999999;
        margin-bottom: 10px;
        div {
            width: 100px;
        }
        span {
            color: #222222;
            margin-left: 65px;
        }
        .blue {
            font-size: 16px;
            color: #2ea7e0;
        }
    }
}
.accept {
    display: flex;
    font-size: 16px;
    color: #999999;
    span {
        margin-top: 15px;
    }
    div {
        flex: 1;
        height: 160px;
        border: 1px solid #eeeeee;
        padding: 15px 10px;
        margin-left: 20px;
    }
}
.accepting {
    padding: 20px;
    border: 1px solid #eeeeee;
    .cell {
        font-size: 16px;
        color: #222222;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        div {
            width: 100px;
            color: #999999;
            margin-right: 50px;
        }
        span {
            color: #e40012;
        }
    }
}
.wait {
    font-size: 18px;
    color: #999999;
    text-align: center;
    margin-top: 30px;
}
.wait-btn {
    padding: 13px 29px;
    background: #d1d1d1 !important;
}
.give-up {
    margin-left: 80px;
    font-size: 18px;
    color: #e40012;
}
.no-win {
    text-align: center;
    font-size: 18px;
    color: #999999;
    margin-top: 150px;
    margin-bottom: 70px;
    span {
        color: #e40012;
    }
    img {
        width: 56px;
        height: 56px;
        display: block;
        margin: 0 auto 20px;
    }
}
.time-out {
    display: flex;
    align-items: center;
    margin: 100px 0;
    color: #ffcc00;
    font-size: 18px;
    justify-content: center;
    img {
        width: 38px;
        height: 32px;
        margin-right: 20px;
    }
}
.foot-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 90px;
}
.orders {
    // margin-top: 90px;
    // width: 247px;
    // height: 50px;
    padding: 13px 100px;
    background: #e40012;
    color: #fff;
    font-size: 18px;
    border-radius: 4px;
    // margin: 90px 0 0;
}
.upload {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 30px 0;
    color: #999999;
    .lable {
        margin-right: 20px;
    }
}
/deep/.el-upload--picture-card {
    width: 90px;
    height: 90px;
    line-height: 100px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 90px;
    height: 90px;
}
/deep/.el-form-item__label {
    line-height: normal;
}
.l-hint {
    div {
        color: #2ea7e0;
        margin-bottom: 10px;
    }
    font-size: 14px;
    color: #222222;
}
.hui {
    text-align: center;
    font-size: 14px;
    color: #999999;
    margin-top: 20px;
    margin-bottom: 30px;
}
.tags {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    line-height: normal !important;
    div {
        padding: 6px 14px;
        border: 1px solid #dbdbdb;
        border-radius: 4px;
        margin-right: 10px;
        // margin-bottom: 10px;
        cursor: pointer;
    }
    .active {
        padding: 6px 14px;
        border: 1px solid #e40012;
        color: #e40012;
    }
}
.backJd {
    margin: 20px 0;
    color: #222;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.reason {
    display: flex;
    color: #222;
    align-items: center;
    margin-bottom: 20px;
    span {
        margin-right: 10px;
        color: #999999;
        font-size: 16px;
    }
}
.foot-content {
    margin: 20px 0;
    .cell {
        color: #222222;
        font-size: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        div {
            width: 100px;
            color: #999999;
            margin-right: 50px;
        }
    }
}
.qr-code {
    width: 257px;
    height: 257px;
    margin: 40px auto;
    background: red;
    img {
        width: 100%;
        height: 100%;
    }
}
.evaluation {
    padding: 20px 0;
    border-bottom: 1px solid #eeeeee;
    font-size: 22px;
    color: #222;
}
.evaluation-info {
    margin: 30px 0 50px;
    font-size: 16px;
    .cell {
        display: flex;
        color: #222;
        align-items: center;
        margin-bottom: 30px;
        .title {
            width: 100px;
            color: #999;
            margin-right: 20px;
        }
    }
}
</style>
